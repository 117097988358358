import type { ImageAssetFragment } from "@commerce-frontend/types";
import { Button } from "@commerce-frontend/ui/base/Button";
import { Icon } from "@commerce-frontend/ui/base/Icon/Icon";
import { DialogClose } from "@commerce-frontend/ui/components/Dialog/Dialog";
import { Link } from "@commerce-frontend/ui/primitives/Link";
import Image from "next/image";
import type { ReactElement } from "react";

type Props = {
	logo?: ImageAssetFragment;
	onBack?: () => void;
	backLabel?: string;
};

const TopBar = ({ logo }: Props): ReactElement<any> => (
	<div className="menuDesktop:px-container menuDesktop:pb-3xl menuDesktop:pt-7xl flex items-center justify-between px-container-sm pt-3xl">
		{logo && (
			<Link href={{ pathname: "/" }} aria-label="homepage" className="block">
				<Image
					unoptimized // svg
					src={logo.filename}
					alt={logo.alt || ""}
					className="h-10 w-auto"
					width={140}
					height={40}
				/>
			</Link>
		)}
		<DialogClose asChild>
			<Button
				className="focus:ring-accent-level-2 group size-11 rounded-xs text-gray-400 hover:bg-gray-50 hover:text-gray-500 focus:bg-gray-50"
				variant="link"
				aria-label={"close"}
			>
				<Icon
					icon="x-close"
					className="size-6 bg-gray-400 transition-colors group-hover:bg-gray-700"
				/>
			</Button>
		</DialogClose>
	</div>
);

export default TopBar;
