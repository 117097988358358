import { useEffect, useSyncExternalStore } from "react";
import { type Token, getJWT } from "~/lib/helpers/auth";

const listeners: (() => void)[] = [];
let token: Token | undefined = undefined;

export const notifyTokenChange = (): void => {
	token = getJWT();
	listeners.forEach((listener) => listener());
};

export const useUserToken = (): Token | undefined => {
	useEffect(() => {
		token = getJWT();
	}, []);

	return useSyncExternalStore(
		(listener) => {
			listeners.push(listener);

			return () => {
				const index = listeners.findIndex((value) => value === listener);
				if (index >= 0) {
					listeners.splice(index, 1);
				}
			};
		},
		() => token,
		() => token,
	);
};
