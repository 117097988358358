import { countryOf } from "@commerce-frontend/i18n/helpers";
import Image from "next/image";

type Props = {
	locale: string;
	className?: string;
	width?: number;
	height?: number;
};

export const IconFlag = (args: Props) => (
	<Image
		className={args.className ?? "h-5 w-7 rounded"}
		src={flagPath(args.locale)}
		width={args.width ?? "28"}
		height={args.height ?? "20"}
		alt=""
		unoptimized
	/>
);

const flagPath = (locale: string) => `/_static/icons/flags/${countryOf(locale).toLowerCase()}.svg`;
