import * as Popover from "@radix-ui/react-popover";
import type { ComponentPropsWithRef, ElementRef } from "react";
import { forwardRef } from "react";
import { cn } from "../../helpers/styles";

export const PopoverRoot = Popover.Root;
export const PopoverPortal = Popover.Portal;
export const PopoverTrigger = Popover.Trigger;

export const PopoverContent = forwardRef<
	ElementRef<typeof Popover.Content>,
	ComponentPropsWithRef<typeof Popover.Content>
>(({ children, className, ...props }, ref) => {
	return (
		<Popover.Content
			ref={ref}
			className={cn(
				"flex flex-col gap-xl relative shadow-3 p-2xl pr-8 z-50 max-w-80 rounded-md bg-white",
				className,
			)}
			{...props}
		>
			{children}
		</Popover.Content>
	);
});

PopoverContent.displayName = "PopoverContent";

export const PopoverClose = forwardRef<
	ElementRef<typeof Popover.Close>,
	ComponentPropsWithRef<typeof Popover.Close>
>(({ children, className, ...props }, ref) => {
	return (
		<Popover.Close
			ref={ref}
			className={cn("absolute top-xl right-xl p-xs text-gray-500", className)}
			{...props}
		>
			{children}
		</Popover.Close>
	);
});

PopoverClose.displayName = "PopoverClose";
