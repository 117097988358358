import { usePathname as useInternalPathname } from "@commerce-frontend/i18n/navigation";
import type { MainMenuFragment, MenuLinkFragment } from "@commerce-frontend/types";
import type { IconProps } from "@commerce-frontend/ui/base/Icon/Icon";
import { Icon } from "@commerce-frontend/ui/base/Icon/Icon";
import {
	ListItem,
	ListItemChevron,
	ListItemContent,
	ListItemIcon,
	ListItemLabel,
	ListItemTrailingLabel,
} from "@commerce-frontend/ui/components/ListItem/ListItem";
import { cn } from "@commerce-frontend/ui/helpers/styles";
import type { Props as PrimitiveButtonProps } from "@commerce-frontend/ui/primitives/Button/Button";
import { usePathname } from "next/navigation";
import type { ComponentProps } from "react";
import { useMegaMenu } from "./MegaMenuContext";

type Props = Omit<
	PrimitiveButtonProps & {
		icon?: IconProps["icon"];
		label: string;
		trailingLabel?: string;
		locale?: ComponentProps<typeof ListItem>["locale"];
		trigger?: () => void;
		showChevron: boolean;
	} & React.ComponentPropsWithoutRef<typeof ListItem>,
	"onClick"
>;

// MenuItem can be either a link (with href) or a button (with onClick trigger)

const MenuItem = ({
	children,
	label,
	icon,
	trigger,
	showChevron,
	trailingLabel,
	...props
}: Props) => {
	const pathname = usePathname();
	const internalPathname = useInternalPathname();
	const { setOpen } = useMegaMenu();

	const handleClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
		if (props?.href) {
			if (pathname === props.href || internalPathname === props.href) {
				e.preventDefault();
				setOpen(false);
			}
			return;
		}

		if (trigger) {
			trigger();
		}
	};

	return (
		<ListItem className={cn(trailingLabel && "min-h-14 pr-2xl")} {...props} onClick={handleClick}>
			{icon && (
				<ListItemIcon>
					<Icon icon={icon} />
				</ListItemIcon>
			)}
			{children}
			<ListItemContent>
				<ListItemLabel>{label}</ListItemLabel>
			</ListItemContent>
			{!props.href && showChevron && (
				<ListItemIcon>
					<ListItemChevron />
				</ListItemIcon>
			)}
			{trailingLabel && <ListItemTrailingLabel>{trailingLabel}</ListItemTrailingLabel>}
		</ListItem>
	);
};

export type MainMenuItems = (MainMenuFragment | MenuLinkFragment)[];

type MenuItemsProps = {
	items: MainMenuItems;
	selectedItem?: MainMenuFragment | null;
	onTrigger?: (item: MainMenuFragment) => void;
};

export const MenuItems = ({ items, selectedItem, onTrigger }: MenuItemsProps) => {
	return (
		<ul className="flex flex-col gap-xs">
			{items.map((item) => (
				<li key={item.label}>
					<MenuItem
						label={item.label}
						variant={item === selectedItem ? "active" : null}
						href={item.__typename === "MenuLink" ? item?.link?.url : undefined}
						trigger={item.__typename === "MenuItem" ? () => onTrigger?.(item) : undefined}
						showChevron={true}
					/>
				</li>
			))}
		</ul>
	);
};

export default MenuItem;
