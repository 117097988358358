import { type ImageAssetFragment, graphql } from "@commerce-frontend/types";
import type { HTMLAttributes } from "react";
import { Image as BaseImage, type Props as BaseImageProps } from "~/components/Image/Image";

graphql(/* GraphQL */ `
	fragment ImageAsset on ContentAsset {
		filename
		alt
		focus
	}
`);

export type AssetImageProps = {
	data?: ImageAssetFragment | null;
	alt?: string;
} & Omit<HTMLAttributes<HTMLImageElement>, "src" | "alt" | "focus"> &
	Omit<BaseImageProps, "src" | "alt" | "focus">;

export const AssetImage = ({ data, alt, ...props }: AssetImageProps) =>
	data ? (
		// https://github.com/vercel/next.js/discussions/18474
		<BaseImage
			width="0"
			height="0"
			sizes="100vw"
			alt={alt || data.alt || ""}
			{...props}
			src={data.filename}
			focus={data.focus}
		/>
	) : null;
