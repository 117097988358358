"use client";

import type { SiteFragment } from "@commerce-frontend/types";
import { Container } from "@commerce-frontend/ui/base/Container/Container";
import { Icon } from "@commerce-frontend/ui/base/Icon/Icon";
import { Button } from "@commerce-frontend/ui/primitives/Button";
import { Link } from "@commerce-frontend/ui/primitives/Link";
import { useTranslations } from "next-intl";
import { useEffect, useState } from "react";
import { getLocalStorageItem, setLocalStorageItem } from "~/lib/helpers/local-storage";

type Props = {
	data?: SiteFragment | null;
};

export const NotificationBanner = ({ data }: Props) => {
	const t = useTranslations("NotificationBanner");
	const banner = data?.notificationBanners?.[0] ?? null;
	const [isOpen, setIsOpen] = useState(false);
	const localStorageKey = "notification-banners";

	const getClosedBanners = () => {
		const closedBanners: string[] = JSON.parse(
			getLocalStorageItem(localStorageKey) ?? "[]",
		) as string[];

		return closedBanners;
	};

	useEffect(() => {
		if (!banner?.id) return;

		const closedBanners = getClosedBanners();

		if (!closedBanners.includes(banner.id)) {
			setIsOpen(true);
		}
	}, [banner]);

	const handleClose = () => {
		setIsOpen(false);

		if (!banner?.id) return;

		const closedBanners = getClosedBanners();

		if (!closedBanners.includes(banner.id)) {
			closedBanners.push(banner.id);
			setLocalStorageItem(localStorageKey, JSON.stringify(closedBanners));
		}
	};

	if (!banner || !isOpen) {
		return;
	}

	const { text, optionalLink, linkLabel } = banner;

	return (
		<div className="relative bg-brand-700 py-xl lg:py-lg z-10">
			<Container>
				<div className="text-white flex items-center justify-between gap-xl">
					<div className="hidden size-12 items-center justify-center rounded-lg bg-brand-500 p-3 lg:flex">
						<Icon icon="announcement-01" className="size-6 bg-white" />
					</div>
					<div className="flex flex-col mr-auto pr-12 lg:flex-row lg:gap-sm">
						<span className="font-semibold">{text}</span>
						{optionalLink?.url ? (
							<Link
								href={optionalLink.url}
								className="inline-flex text-brand-200 underline underline-offset-2 hover:no-underline"
							>
								{linkLabel}
							</Link>
						) : null}
					</div>
					<Button
						className="absolute flex items-center justify-center right-2 top-2 group size-10 p-md focus:ring-accent-level-2 lg:static"
						aria-label={t("close")}
						onClick={handleClose}
					>
						<Icon
							icon="x-close"
							className="size-5 bg-white opacity-70 transition-colors focus:bg-gray-900"
						/>
					</Button>
				</div>
			</Container>
		</div>
	);
};
