import type { Locale } from "@commerce-frontend/i18n/types";
import type { CompanyReview } from "@commerce-frontend/types";
import { Button } from "@commerce-frontend/ui/base/Button";
import { Icon } from "@commerce-frontend/ui/base/Icon/Icon";
import {
	PopoverClose,
	PopoverContent,
	PopoverPortal,
	PopoverRoot,
	PopoverTrigger,
} from "@commerce-frontend/ui/components/Popover/Popover";
import { Button as PrimitiveButton } from "@commerce-frontend/ui/primitives/Button";
import { useTranslations } from "next-intl";
import { StarRating } from "~/components/StarRating/StarRating";
import { TrustedShopsRating } from "~/components/TrustedShopsRating/TrustedShopsRating";
import { useEnvVar } from "~/lib/config/context";
import { getIdByLocale, getReviewUrlByLocale } from "~/lib/review-channels";

type Props = {
	companyReview?: CompanyReview;
	locale: Locale;
};

export const TrustedShopsPopover = ({ companyReview, locale }: Props) => {
	const t = useTranslations("TrustedShopsPopover");
	const channelIds = useEnvVar("NEXT_PUBLIC_REVIEW_CHANNEL_IDS");
	const channelId = getIdByLocale(locale, channelIds);

	if (!companyReview || !channelId) {
		return null;
	}

	const { amount, totalAmount, averageRating } = companyReview;

	if (!averageRating || !amount || !totalAmount) {
		return null;
	}

	const reviewUrl = getReviewUrlByLocale(locale, channelId);

	return (
		<PopoverRoot>
			<PopoverTrigger asChild>
				<PrimitiveButton className="flex items-center">
					<TrustedShopsRating rating={averageRating} />
				</PrimitiveButton>
			</PopoverTrigger>
			<PopoverPortal>
				<PopoverContent hideWhenDetached={true}>
					<StarRating rating={averageRating} />
					<div className="font-bold">
						{t("title", {
							rating: averageRating,
						})}
					</div>
					<p className="text-sm">
						{t.rich("subtitle", {
							link: (chunks) => (
								<Button
									variant="linkColor"
									className="whitespace-normal"
									href={reviewUrl}
									tabIndex={-1}
								>
									{chunks}
								</Button>
							),
							amount,
							totalAmount,
							averageRating,
						})}
					</p>
					<div className="text-sm">
						<Button
							variant="linkColor"
							className="whitespace-normal text-left"
							href={reviewUrl}
							tabIndex={-1}
						>
							{t("readMore")}
						</Button>
					</div>
					<PopoverClose aria-label="Close">
						<Icon icon="x" />
					</PopoverClose>
				</PopoverContent>
			</PopoverPortal>
		</PopoverRoot>
	);
};
