let isAvailable: boolean | undefined;

const isLocalStorageAccessible = () => {
	if (isAvailable === undefined) {
		try {
			window.localStorage.setItem("test", "test");
			window.localStorage.removeItem("test");
			isAvailable = true;
		} catch (e) {
			isAvailable = false;
		}
	}
	return isAvailable;
};

export const getLocalStorageItem = <T>(key: string): T | undefined => {
	if (typeof window !== "undefined" && isLocalStorageAccessible()) {
		const storedValue = window.localStorage.getItem(key);
		return storedValue ? (JSON.parse(storedValue) as T) : undefined;
	}
};

export const setLocalStorageItem = <T>(key: string, value: T): void => {
	if (typeof window !== "undefined" && isLocalStorageAccessible()) {
		window.localStorage.setItem(key, JSON.stringify(value));
	}
};

export const resetLocalStorageItem = (key: string): void => {
	if (getLocalStorageItem(key)) {
		window.localStorage.removeItem(key);
	}
};
