import { Icon } from "@commerce-frontend/ui/base/Icon/Icon";
import { Link } from "@commerce-frontend/ui/primitives/Link";
import { useTranslations } from "next-intl";

type Props = {
	isAuthenticated: boolean;
};

export const AccountLink = ({ isAuthenticated }: Props) => {
	const t = useTranslations("AccountLink");

	return (
		<Link
			className="focus:ring-accent-level-2 flex h-11 shrink-0 flex-col items-center justify-center gap-xxs transition-all hover:bg-gray-50 focus:bg-white lg:w-auto xxs:px-md"
			href={{ pathname: isAuthenticated ? "/account" : "/account/login" }}
			aria-label={t(isAuthenticated ? "account" : "login")}
		>
			<Icon icon="user" className="size-6 shrink-0 bg-gray-500" />
			<span className="text-xs font-medium">{t(isAuthenticated ? "account" : "login")}</span>
		</Link>
	);
};
