import type { Locale } from "@commerce-frontend/i18n/types";

export const getIdByLocale = (
	locale: Locale,
	ids: Record<string, string> | undefined,
): string | undefined => {
	return ids?.[locale] ?? "";
};

export const getReviewUrlByLocale = (locale: Locale, channelId: string): string | undefined => {
	const reviewUrlByLocaleMap: Record<string, string> = {
		"nl-NL": `https://www.trustedshops.nl/verkopersbeoordeling/info_${channelId}.html`,
		"nl-BE": `https://www.trustedshops.be/nl/verkopersbeoordeling/info_${channelId}.html`,
		"fr-BE": `https://www.trustedshops.be/fr/evaluation/info_${channelId}.html`,
		"de-DE": `https://www.trustedshops.de/bewertung/info_${channelId}.html`,
		"es-ES": `https://www.trustedshops.es/evaluacion/info_${channelId}.html`,
		"fr-FR": `https://www.trustedshops.fr/evaluation/info_${channelId}.html`,
	};

	return reviewUrlByLocaleMap[locale];
};
