"use client";

import { Input } from "@commerce-frontend/ui/base/Input/Input";
import { Label } from "@commerce-frontend/ui/base/Label/Label";
import { FormField, FormMessage } from "@commerce-frontend/ui/components/Form/Form";
import type { HTMLAttributes } from "react";
import { useId } from "react";
import { get, useFormContext } from "react-hook-form";
import { FormDescription } from "../FormDescription";

type Props = {
	name: string;
	placeholder?: string;
	description?: string;
	type?: string;
	label?: string;
	labelClassName?: string;
	inputClassName?: string;
	inputTabIndex?: number;
	descriptionClassName?: string;
	min?: string;
	max?: string;
	step?: "any" | number;
	disabled?: boolean;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	suggestions?: string[] | null;
	autoComplete?: string;
} & Omit<HTMLAttributes<HTMLDivElement>, "onChange" | "onBlur">;

export function InputField({
	label,
	description,
	name,
	type,
	placeholder,
	labelClassName,
	inputClassName,
	inputTabIndex,
	descriptionClassName,
	onChange,
	onBlur,
	max,
	min,
	step,
	disabled,
	suggestions,
	autoComplete,
	...attributes
}: Props) {
	const id = useId();
	const errorId = `${id}:error`;
	const descriptionId = `${id}:description`;

	const { register, formState } = useFormContext();
	const error = get(formState.errors, name) as (typeof formState.errors)[string];
	const invalid = Boolean(error?.message);
	const inputState = formState.submitCount > 0 ? (invalid ? "error" : "success") : undefined;

	const getInputOptions = () => {
		switch (type) {
			case "number":
				return { valueAsNumber: true };
			case "date":
				return { valueAsDate: true };
			default:
				return {};
		}
	};

	return (
		<FormField {...attributes} aria-invalid={invalid}>
			{label && (
				<Label htmlFor={id} className={labelClassName}>
					{label}
				</Label>
			)}

			<Input
				{...register(name, {
					onChange,
					onBlur,
					...getInputOptions(),
				})}
				disabled={disabled}
				type={type}
				id={id}
				className={inputClassName}
				placeholder={placeholder}
				max={max}
				min={min}
				step={step}
				aria-errormessage={errorId}
				aria-describedby={description && descriptionId}
				aria-invalid={invalid}
				suggestions={suggestions}
				autoComplete={autoComplete}
				tabIndex={inputTabIndex}
				state={inputState}
			/>

			{description && (
				<FormDescription className={descriptionClassName} id={descriptionId}>
					{description}
				</FormDescription>
			)}

			{formState.submitCount > 0 && error?.message ? (
				<FormMessage id={errorId}>{error.message as string}</FormMessage>
			) : undefined}
		</FormField>
	);
}
