"use client";

import { useAlternates } from "@commerce-frontend/i18n/alternates";
import {
	countryDisplayName,
	countryOf,
	languageDisplayName,
	languageOf,
} from "@commerce-frontend/i18n/helpers";
import type {
	ImageAssetFragment,
	MainMenuFragment,
	MenuLinkFragment,
} from "@commerce-frontend/types";
import { Button } from "@commerce-frontend/ui/base/Button";
import { Icon } from "@commerce-frontend/ui/base/Icon/Icon";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	DialogTrigger,
} from "@commerce-frontend/ui/components/Dialog/Dialog";
import { useTranslations } from "next-intl";
import { usePathname } from "next/navigation";
import { useEffect } from "react";
import { useIsAuthenticated } from "~/hooks/useIsAuthenticated";
import type { HeaderStaticLinks } from "~/lib/header-static-links";
import { siteConfig, storeConfigs } from "~/lib/store-config";
import { IconFlag } from "../../IconFlag/IconFlag";
import type { SelectedItems } from "./MegaMenuContext";
import { useMegaMenu } from "./MegaMenuContext";
import type { MainMenuItems } from "./MenuItem";
import MenuItem, { MenuItems } from "./MenuItem";
import TopBar from "./TopBar";

type MenuItems = (MainMenuFragment | MenuLinkFragment)[];

type Props = {
	locale: string;
	topMenuItems: MainMenuItems;
	staticLinks: HeaderStaticLinks;
	logo?: ImageAssetFragment;
};

const MenuNavigation = ({ children }: React.PropsWithChildren) => {
	return (
		<nav className="max-menuDesktop:pt-lg h-full max-h-screen flex-1 px-container-sm pb-container-sm">
			{children}
		</nav>
	);
};

const MenuGroup = ({ children }: React.PropsWithChildren) => {
	return <div className="border-b-4 border-gray-50 last:border-b-0">{children}</div>;
};

const MenuHeader = ({ children }: React.PropsWithChildren) => {
	// @TODO: Update to heading typography element, but the styling needs to be consistent
	return <div className="menuDesktop:items-end mb-xs flex h-11 items-center py-sm">{children}</div>;
};

const MenuColumn = ({ children }: React.PropsWithChildren) => {
	return (
		<div className="custom-scrollbar max-w-column max-menuDesktop:absolute max-menuDesktop:z-30 menuDesktop:w-80 menuDesktop:border-l-4 menuDesktop:border-gray-50 menuDesktop:first:border-l-0 size-full w-full min-w-80 overflow-y-scroll bg-white 2xl:w-column">
			{children}
		</div>
	);
};

export function MegaMenu({ locale: currentLocale, topMenuItems, staticLinks, logo }: Props) {
	const t = useTranslations("MegaMenu");
	const navigationBarTranslations = useTranslations("NavigationBar");
	const alternates = useAlternates(currentLocale, siteConfig.locales);
	const pathname = usePathname();
	const { open, setOpen, selectedItems, setSelectedItems, languageMenuOpen, setLanguageMenuOpen } =
		useMegaMenu();
	const isAuthenticated = useIsAuthenticated();

	const resetSelectedItems = () => {
		setSelectedItems([null, null]);
	};

	const selectItems = (selectedItems: SelectedItems) => {
		setSelectedItems(selectedItems);
		setLanguageMenuOpen(false);
	};

	// Close the menu and reset the selected items after route change

	useEffect(() => {
		setOpen(false);
		resetSelectedItems();
		setLanguageMenuOpen(false);
	}, [pathname]);

	const Content = () => {
		return (
			<div className="flex h-dvh flex-col">
				<TopBar logo={logo} />

				<div className="menuDesktop:px-xl relative flex grow overflow-hidden">
					{/* Main menu (level 0) */}

					<MenuColumn>
						<div>
							<MenuGroup>
								<MenuNavigation>
									<MenuHeader>
										<div className="text-gray-700">{t("what-are-you-looking-for")}</div>
									</MenuHeader>
									<MenuItems
										items={topMenuItems}
										selectedItem={selectedItems[0]}
										onTrigger={(item) => {
											if (item === selectedItems[0]) {
												resetSelectedItems();
											} else {
												selectItems([item, null]);
											}
										}}
									/>
								</MenuNavigation>
							</MenuGroup>

							<MenuGroup>
								<MenuNavigation>
									<MenuHeader>
										<div className="text-gray-700">{t("how-can-we-help-you")}</div>
									</MenuHeader>
									<ul className="flex flex-col gap-xs">
										{staticLinks.service && (
											<li>
												<MenuItem
													href={staticLinks.service.link.url}
													icon="message-question-circle"
													label={staticLinks.service.label}
													showChevron={false}
												/>
											</li>
										)}
										{staticLinks.business && (
											<li>
												<MenuItem
													href={staticLinks.business.link.url}
													icon="package"
													label={staticLinks.business.label}
													showChevron={false}
												/>
											</li>
										)}
										<li>
											<MenuItem
												href={{
													pathname: "/stores",
												}}
												icon="building-02"
												label={navigationBarTranslations("store-button-label")}
												showChevron={false}
											/>
										</li>
									</ul>
								</MenuNavigation>
							</MenuGroup>

							<MenuGroup>
								<MenuNavigation>
									<MenuHeader>
										<div className="text-gray-700">{t("personal-menu")}</div>
									</MenuHeader>
									<ul className="flex flex-col gap-xs">
										<li>
											<MenuItem
												label={t("cart")}
												href={{ pathname: "/cart" }}
												icon="shopping-bag"
												showChevron={false}
											/>
										</li>
										<li>
											<MenuItem
												label={isAuthenticated ? t("account") : t("login")}
												href={
													isAuthenticated
														? { pathname: "/account" }
														: { pathname: "/account/login" }
												}
												icon="user"
												showChevron={false}
											/>
										</li>
									</ul>
								</MenuNavigation>
							</MenuGroup>
						</div>

						<div className="sticky bottom-0 border-t border-gray-300 bg-white">
							<MenuNavigation>
								<MenuHeader>
									<div className="text-gray-700">{t("delivery-and-language")}</div>
								</MenuHeader>

								<MenuItem
									label={countryDisplayName(countryOf(currentLocale), languageOf(currentLocale))}
									trigger={() => {
										if (languageMenuOpen) {
											setLanguageMenuOpen(false);
										} else {
											resetSelectedItems();
											setLanguageMenuOpen(true);
										}
									}}
									showChevron={true}
									variant={languageMenuOpen ? "active" : null}
								>
									<div className="flex items-center justify-center">
										<IconFlag
											locale={currentLocale}
											className="size-6 rounded-full object-cover"
											width={24}
											height={24}
										/>
									</div>
								</MenuItem>
							</MenuNavigation>
						</div>
					</MenuColumn>

					{/* Language menu */}

					{languageMenuOpen ? (
						<MenuColumn>
							<MenuNavigation>
								<MenuHeader>
									<Button
										className="menuDesktop:hidden"
										variant="tertiaryColor"
										size="xl"
										onClick={() => {
											selectItems([selectedItems[0], null]);
										}}
									>
										<Icon icon="arrow-left" className="bg-brand" />
									</Button>
									<div className="text-gray-700 max-sm:px-[18px] max-sm:py-lg">
										{t("delivery-and-language")}
									</div>
								</MenuHeader>

								<ul className="flex flex-col gap-xs">
									{siteConfig.locales
										.map((locale) => [locale, alternates.find((a) => a.locale === locale)] as const)
										.filter(([locale]) => locale !== currentLocale)
										.map(([locale, alternate]) => {
											const hasMoreLanguages = storeConfigs[countryOf(locale)]?.locales?.length > 1;

											return (
												<li key={locale}>
													<MenuItem
														label={countryDisplayName(countryOf(locale), languageOf(locale))}
														trailingLabel={
															hasMoreLanguages ? languageDisplayName(locale) : undefined
														}
														showChevron={false}
														variant={locale === currentLocale ? "active" : null}
														trigger={() => {
															const link = alternate?.href;
															if (link) {
																window.location.href = link;
															}
														}}
													>
														<IconFlag
															className="size-6 rounded-full object-cover mr-xs"
															width={24}
															height={24}
															locale={locale}
														/>
													</MenuItem>
												</li>
											);
										})}
								</ul>
							</MenuNavigation>
						</MenuColumn>
					) : (
						<>
							{selectedItems[0] ? (
								<MenuColumn>
									<MenuNavigation>
										<MenuHeader>
											<Button
												className="max-menuDesktop:hidden"
												href={selectedItems[0]?.optionalLink?.url}
												variant="linkColor"
												size="xl"
											>
												{selectedItems[0].label}
											</Button>
											<Button
												className="menuDesktop:hidden"
												variant="tertiaryColor"
												size="xl"
												onClick={() => {
													resetSelectedItems();
												}}
											>
												<Icon icon="arrow-left" className="bg-brand" />
											</Button>
											<Button
												className="menuDesktop:hidden"
												href={selectedItems[0]?.optionalLink?.url}
												variant="tertiaryColor"
												size="xl"
											>
												{selectedItems[0].label}
											</Button>
										</MenuHeader>
										<MenuItems
											items={selectedItems[0]?.subMenuItems as MainMenuItems}
											selectedItem={selectedItems[1]}
											onTrigger={(item) => {
												if (item === selectedItems[1]) {
													selectItems([selectedItems[0], null]);
												} else {
													selectItems([selectedItems[0], item]);
												}
											}}
										/>
									</MenuNavigation>
								</MenuColumn>
							) : null}

							{selectedItems[1] ? (
								<MenuColumn>
									<MenuNavigation>
										<MenuHeader>
											{/* Back button on mobile */}
											<Button
												className="menuDesktop:hidden"
												variant="tertiaryColor"
												size="xl"
												onClick={() => {
													selectItems([selectedItems[0], null]);
												}}
											>
												<Icon icon="arrow-left" className="bg-brand" />
											</Button>
											{/* Link or a text */}
											{selectedItems[1]?.optionalLink?.url ? (
												<>
													<Button
														className="max-sm:hidden"
														href={selectedItems[1]?.optionalLink?.url}
														variant="linkColor"
														size="xl"
													>
														{selectedItems[1].label}
													</Button>
													<Button
														className="menuDesktop:hidden"
														href={selectedItems[1]?.optionalLink?.url}
														variant="tertiaryColor"
														size="xl"
													>
														{selectedItems[1].label}
													</Button>
												</>
											) : (
												<div className="text-gray-700 max-sm:px-[18px] max-sm:py-lg">
													{selectedItems[1].label}
												</div>
											)}
										</MenuHeader>
										<ul className="flex flex-col gap-xs">
											{selectedItems[1].subMenuItems?.map((menuItem) => (
												<li key={menuItem.label}>
													<MenuItem
														label={menuItem.label}
														variant={menuItem === selectedItems[1] ? "active" : null}
														showChevron={true}
														href={
															menuItem.__typename === "MenuLink" ? menuItem?.link?.url : undefined
														}
													/>
												</li>
											))}
										</ul>
									</MenuNavigation>
								</MenuColumn>
							) : null}
						</>
					)}
				</div>
			</div>
		);
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger
				className="menuDesktop:hidden flex flex-col items-center px-md"
				aria-label={t("open-menu")}
			>
				<Icon icon="menu" className="size-6" />
				<div className="text-xs font-medium">{t("menu")}</div>
			</DialogTrigger>

			<DialogContent
				className="menuDesktop:w-auto menuDesktop:pl-8xl menuMobile:w-column flex w-[350px] min-w-80 max-w-[calc(100%-20px)] flex-col gap-0 shadow-5 sm:w-column sm:max-w-none"
				aria-label={t("accessibility-name")}
				onOpenAutoFocus={(e) => {
					// prevent focus to the close button
					e.preventDefault();
				}}
			>
				<DialogTitle>{t("accessibility-name")}</DialogTitle>
				<Content />
			</DialogContent>
		</Dialog>
	);
}
