"use client";

import { languageDisplayName } from "@commerce-frontend/i18n/helpers";
import type { CountryCode } from "@commerce-frontend/i18n/types";
import { IconFlag } from "../IconFlag/IconFlag";
import { useMegaMenu } from "./MegaMenu/MegaMenuContext";

type Props = {
	locale: string;
};

export const LanguageButton = ({ locale }: Props) => {
	const countryCode = new Intl.Locale(locale).region as CountryCode;
	const { openMegaMenu } = useMegaMenu();

	return (
		<button
			aria-label={languageDisplayName(locale)}
			className="focus:ring-accent-level-2 relative flex h-11 shrink-0 flex-col items-center justify-center gap-xxs px-md transition-all hover:bg-gray-50 focus:bg-white lg:w-auto"
			onClick={() => openMegaMenu(null, true)}
		>
			<IconFlag
				locale={locale}
				className="size-6 rounded-full object-cover"
				width={24}
				height={24}
			/>
			<span className="text-xs font-medium">{countryCode}</span>
		</button>
	);
};

// For the suspense fallback
export const LanguageButtonFallback = ({ locale }: { locale: string }) => (
	<IconFlag locale={locale} className="h-4 w-[22px]" width={16} height={16} />
);
