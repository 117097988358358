"use client";

import type { MenuBlockFragment } from "@commerce-frontend/types";
import { Icon } from "@commerce-frontend/ui/base/Icon/Icon";
import { cn } from "@commerce-frontend/ui/helpers/styles";
import { useTranslations } from "next-intl";
import React, { useState } from "react";

export type Props = {
	footerMenu?: MenuBlockFragment[];
	socialsHeading?: string;
	facebook?: string;
	instagram?: string;
	linkedIn?: string;
	youtube?: string;
	tiktok?: string;
	pinterest?: string;
};

const useSocialsMenu = ({
	title,
	facebook,
	instagram,
	linkedIn,
	youtube,
	tiktok,
	pinterest,
}: {
	title?: string;
	facebook?: string;
	instagram?: string;
	linkedIn?: string;
	youtube?: string;
	tiktok?: string;
	pinterest?: string;
}): MenuBlockFragment => {
	const t = useTranslations("Footer");

	const socials: MenuBlockFragment = {
		__typename: "MenuBlock",
		id: "social",
		label: title ?? "",
		menuLinks: [],
	};

	if (facebook) {
		socials.menuLinks.push({
			__typename: "MenuLink",
			id: "facebook",
			label: t("social.facebook"),
			link: {
				url: facebook,
				newTab: true,
			},
		});
	}

	if (pinterest) {
		socials.menuLinks.push({
			__typename: "MenuLink",
			id: "pinterest",
			label: t("social.pinterest"),
			link: {
				url: pinterest,
				newTab: true,
			},
		});
	}

	if (tiktok) {
		socials.menuLinks.push({
			__typename: "MenuLink",
			id: "tiktok",
			label: t("social.tiktok"),
			link: {
				url: tiktok,
				newTab: true,
			},
		});
	}

	if (youtube) {
		socials.menuLinks.push({
			__typename: "MenuLink",
			id: "youtube",
			label: t("social.youtube"),
			link: {
				url: youtube,
				newTab: true,
			},
		});
	}

	if (instagram) {
		socials.menuLinks.push({
			__typename: "MenuLink",
			id: "instagram",
			label: t("social.instagram"),
			link: {
				url: instagram,
				newTab: true,
			},
		});
	}

	if (linkedIn) {
		socials.menuLinks.push({
			__typename: "MenuLink",
			id: "linkedIn",
			label: t("social.linkedin"),
			link: {
				url: linkedIn,
				newTab: true,
			},
		});
	}

	return socials;
};

export const FooterAccordion = React.forwardRef<HTMLDivElement, Props>(
	({ footerMenu, socialsHeading, ...socialLinks }, forwardRef) => {
		const [openedMenu, setOpenedMenu] = useState<string | false>(false);

		const socials = useSocialsMenu({
			title: socialsHeading,
			...socialLinks,
		});

		const menuItems: MenuBlockFragment[] = [...(footerMenu ?? [])];
		if (socials.menuLinks.length) {
			menuItems.push(socials);
		}

		return (
			<div
				ref={forwardRef}
				className={cn([
					"grid grid-cols-1 pb-8 pt-12 md:gap-4xl md:pb-6xl md:pt-7xl",
					(menuItems?.length ?? 0) <= 3 && "md:grid-cols-3",
					menuItems?.length === 4 && "md:grid-cols-2 xl:grid-cols-4",
					menuItems?.length === 5 && "md:grid-cols-3 xl:grid-cols-5",
					(menuItems?.length ?? 0) >= 6 && "md:grid-cols-3 xl:grid-cols-6",
				])}
			>
				{menuItems?.map((item) => (
					<div key={item.id} className="flex flex-col">
						<button
							type="button"
							className="font-body select-none appearance-none overflow-hidden text-ellipsis text-nowrap rounded-none py-2.5 pl-0.5 pr-2 text-left text-md font-semibold text-gray-600 md:cursor-default md:p-0 md:text-sm md:text-gray-500"
							onClick={() => {
								setOpenedMenu(openedMenu === item.id ? false : item.id);
							}}
						>
							<Icon
								icon={openedMenu === item.id ? "chevron-up" : "chevron-down"}
								className="relative float-right ml-2 bg-brand-500 md:hidden my-0.5"
								size="sm"
							/>
							{item.label}
						</button>
						<div
							className={cn([
								"overflow-hidden border-b border-b-gray-200 transition-[max-height] md:max-h-none md:border-b-0",
								openedMenu === item.id ? "max-h-screen" : "max-h-0",
							])}
						>
							<div className="flex flex-col gap-4 pb-3xl pr-4xl pt-xl md:gap-3 md:pb-0 md:pr-0">
								{item.menuLinks.map((child) => (
									<a
										key={child.id}
										href={child.link.url}
										target={child.link.newTab ? "_blank" : "_self"}
										className="inline-block max-w-full select-none self-start overflow-hidden text-ellipsis text-nowrap text-md font-semibold text-gray-600  underline underline-offset-4 transition-colors hover:decoration-transparent focus:bg-orange-dark-200 focus:decoration-gray-900"
									>
										{child.label}
									</a>
								))}
							</div>
						</div>
					</div>
				))}
			</div>
		);
	},
);

FooterAccordion.displayName = "FooterAccordion";
