import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

// Any changes made in the styling should be also be applied in the global.css
const typographyVariants = cva("", {
	variants: {
		type: {
			h1: "display-md lg:display-lg font-semibold",
			h2: "display-sm lg:display-md font-semibold",
			h3: "display-xs lg:display-sm font-semibold",
			h4: "lg:display-xs text-xl font-semibold",
			h5: "text-lg font-semibold lg:text-xl",
			h6: "text-md font-semibold lg:text-lg",
			lead: "text-lg text-gray-600 lg:text-xl",
			a: "rounded-none border-b border-brand-500 font-medium text-brand no-underline transition-all hover:border-transparent hover:text-brand-600 hover:no-underline focus:border-brand-500 focus:bg-orange-dark-200 disabled:bg-transparent",
			p: "text-md",
			blockquote: "mt-6 border-l-2 pl-6 italic",
			list: "my-6 ml-6 list-disc [&>li]:mt-2",
			inlineCode: "relative rounded bg-gray-50 p-xs font-mono text-sm",
			large: "text-lg font-semibold",
			small: "text-sm",
			muted: "text-sm text-gray-600",
			heading: "flex h-11 items-end py-sm text-gray-700",
		},
	},
});

/**
 * Helper function to get the correct typography variant based on the design system
 *
 * This alleviates some of the boilerplate needed in CVA to get the correct variant
 * @returns className combinations for typography based on design system
 */
export const typography = (type: VariantProps<typeof typographyVariants>["type"]): string =>
	typographyVariants({ type });
