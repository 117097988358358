"use client";

import { usePathname } from "@commerce-frontend/i18n/navigation";
import type { Locale } from "@commerce-frontend/i18n/types";
import {
	type MutationNewsletterSignUpArgs,
	type NewsletterCtaFragmentFragment,
	graphql,
} from "@commerce-frontend/types";
import { Button } from "@commerce-frontend/ui/base/Button";
import { cn } from "@commerce-frontend/ui/helpers/styles";
import { emailField } from "@commerce-shared/validation";
import { useClientGqlFetcher } from "@labdigital/graphql-fetcher";
import { type UseMutationResult, useMutation } from "@tanstack/react-query";
import { useLocale, useTranslations } from "next-intl";
import type { HTMLAttributes } from "react";
import { forwardRef, useSyncExternalStore } from "react";
import { z } from "zod";
import { Form } from "~/components/FormField/Form";
import { InputField } from "~/components/FormField/InputField/InputField";
import { useForm } from "~/components/FormField/useForm";
import { useStoreContext } from "~/hooks/useStoreContext";
import { useNavigation } from "~/lib/navigation-provider";
import { deviceData, locationDataFromPathname, userData } from "~/lib/send-gtm-event";
import { useSiteConfig } from "~/lib/site-config-provider";

export type Values = z.infer<typeof subscribeSchema>;

export const subscribeSchema = z.object({
	email: emailField,
});

export type NewsletterFormProp = {
	orientation?: NewsletterCtaFragmentFragment["orientation"];
} & HTMLAttributes<HTMLDivElement>;

export const NewsletterSignUp = graphql(/* GraphQL */ `
	mutation NewsletterSignUp($storeContext: StoreContextInput!, $email: String!) {
		newsletterSignUp(storeContext: $storeContext, email: $email)
	}
`);

const listeners: (() => void)[] = [];

type GlobalFormState = {
	completed: boolean;
	email: string;
};

let globalFormState: Readonly<GlobalFormState> = {
	completed: false,
	email: "",
};

// Update the global completed state and notify all observing components
const setSubmittedEmail = (email?: string): void => {
	const completed = !!email;
	if (completed !== globalFormState.completed || email !== globalFormState.email) {
		globalFormState = {
			completed,
			email: email || "",
		};
		listeners.forEach((listener) => listener());
	}
};

export const useNewsletterSignUp = (): UseMutationResult<
	unknown,
	Error,
	Omit<MutationNewsletterSignUpArgs, "storeContext">
> => {
	const { currency, storeKey, locale } = useStoreContext();
	const storeContext = { currency, storeKey, locale };
	const gqlClientFetch = useClientGqlFetcher();

	return useMutation({
		mutationFn: (variables: Omit<MutationNewsletterSignUpArgs, "storeContext">) =>
			gqlClientFetch(NewsletterSignUp, { ...variables, storeContext }).then((result) => {
				const success = !!result.data?.newsletterSignUp;
				setSubmittedEmail(success ? variables.email : undefined);
				return success;
			}),
	});
};

export const NewsletterForm = forwardRef<HTMLDivElement, NewsletterFormProp>(
	({ orientation, className, ...props }, ref) => {
		const siteConfig = useSiteConfig();
		const t = useTranslations("NewsletterForm");
		const formT = useTranslations("Form");
		const form = useForm(subscribeSchema, {
			email: "",
		});
		const pathname = usePathname();
		const { previousUrl } = useNavigation();
		const locale = useLocale() as Locale;

		const { isPending, mutateAsync: submitSignup } = useNewsletterSignUp();

		const { completed, email: completedEmail } = useSyncExternalStore(
			(listener) => {
				listeners.push(listener);

				return () => {
					const index = listeners.findIndex((value) => value === listener);
					if (index >= 0) {
						listeners.splice(index, 1);
					}
				};
			},
			() => globalFormState,
			() => globalFormState,
		);

		const onSubmit = async (values: Values) => {
			const success = await submitSignup(values);
			if (!success) {
				form.setError("email", {
					message: "general-submit-error",
				});
			}
		};

		if (completed) {
			return (
				<div {...props} ref={ref} className={cn("w-full flex flex-col", className)}>
					<div className="py-lg flex items-center align-middle">
						<div className="mt-0.5 bg-success-600 self-start size-5 rounded-full m-1">
							<span className="absolute -m-1 size-7 before:inline-block before:bg-success-100 before:size-full before:content-[''] before:[mask-image:--icon-check-circle] before:[mask-position:center] before:[mask-repeat:no-repeat] before:[mask-size:contain]" />
						</div>
						<p className="flex pl-2 max-w-3xl text-gray-900">
							{t("success.title", { email: completedEmail })}
						</p>
					</div>
					<p className="ml-7 pl-2 text-gray-600 text-sm">{t("success.body")}</p>
				</div>
			);
		}

		return (
			<div {...props} ref={ref} className={cn("w-full flex", className)}>
				<Form
					className={cn(
						"w-full flex items-start gap-xl flex-col",
						orientation === "horizontal" && "md:flex-row",
					)}
					form={form}
					onSubmit={onSubmit}
					aria-disabled={isPending}
					eventData={() => ({
						event: "form_submission",
						timestamp: Date.now(),
						device: deviceData(),
						user: userData({
							email: form.getValues()?.email,
						}),
						location: locationDataFromPathname({
							locale,
							pathname,
							previousUrl,
						}),
						general: {
							form_type: "newsletter_signup",
						},
					})}
				>
					<div className="w-full flex flex-col gap-y-sm grow">
						<InputField
							className="w-full shrink-0 lg:w-auto"
							name="email"
							placeholder={formT("email-label")}
							inputClassName="border bg-white min-h-11"
							disabled={isPending}
							autoComplete="email"
						/>
						{siteConfig?.privacyPage && siteConfig?.privacyLabel ? (
							<p
								className="text-gray-600 text-sm"
								dangerouslySetInnerHTML={{
									__html: t("disclaimer.text", {
										link: `<a class="underline" href="${siteConfig.privacyPage.url}" target="${siteConfig.privacyPage.newTab ? "_blank" : "_self"}">${t("disclaimer.link")}</a>`,
									}),
								}}
							/>
						) : null}
					</div>
					<Button
						type="submit"
						className={cn(orientation === "horizontal" ? "w-full md:w-auto grow-0" : "grow-0")}
						variant="primary"
						disabled={isPending}
					>
						{t("submit")}
					</Button>
				</Form>
			</div>
		);
	},
);

NewsletterForm.displayName = "NewsletterForm";
