import type { MainMenuFragment } from "@commerce-frontend/types";
import type { Dispatch, SetStateAction } from "react";
import { createContext, useContext, useState } from "react";

export type SelectedItems = [MainMenuFragment | null, MainMenuFragment | null];

export type MegaMenuState = {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	selectedItems: SelectedItems;
	setSelectedItems: Dispatch<SetStateAction<SelectedItems>>;
	languageMenuOpen: boolean;
	setLanguageMenuOpen: Dispatch<SetStateAction<boolean>>;
	openMegaMenu: (
		menuItem: MainMenuFragment | null,
		languageMenu: boolean | null | undefined,
	) => void;
};

export const MegaMenuContext = createContext<MegaMenuState>({
	open: false,
	setOpen: () => {},
	selectedItems: [null, null],
	setSelectedItems: () => {},
	languageMenuOpen: false,
	setLanguageMenuOpen: () => {},
	openMegaMenu: () => {},
});

export const MegaMenuProvider = ({ children }: { children: React.ReactNode }) => {
	const [open, setOpen] = useState(false);
	const [selectedItems, setSelectedItems] = useState<SelectedItems>([null, null]);
	const [languageMenuOpen, setLanguageMenuOpen] = useState(false);

	const openMegaMenu = (
		menuItem: MainMenuFragment | null,
		languageMenu: boolean | null | undefined,
	) => {
		setOpen(true);

		if (languageMenu) {
			setSelectedItems([null, null]);
			setLanguageMenuOpen(true);
			return;
		}

		setLanguageMenuOpen(false);
		setSelectedItems([menuItem, null]);
	};

	return (
		<MegaMenuContext.Provider
			value={{
				open,
				setOpen,
				selectedItems,
				setSelectedItems,
				languageMenuOpen,
				setLanguageMenuOpen,
				openMegaMenu,
			}}
		>
			{children}
		</MegaMenuContext.Provider>
	);
};

export const useMegaMenu = () => useContext(MegaMenuContext);
