"use client";

import type { SearchEventData } from "@commerce-frontend/gtm-ecommerce";
import { usePathname, useRouter } from "@commerce-frontend/i18n/navigation";
import type { Locale } from "@commerce-frontend/i18n/types";
import { Button } from "@commerce-frontend/ui/base/Button";
import { Icon } from "@commerce-frontend/ui/base/Icon/Icon";
import { Input } from "@commerce-frontend/ui/base/Input/Input";
import { useLocale, useTranslations } from "next-intl";
import { useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { useNavigation } from "~/lib/navigation-provider";
import {
	deviceData,
	locationDataFromPathname,
	searchData,
	sendGTMEvent,
	userData,
} from "~/lib/send-gtm-event";

export const SearchBar = () => {
	const searchParams = useSearchParams();
	const router = useRouter();
	const locale = useLocale() as Locale;
	const pathname = usePathname();
	const { previousUrl } = useNavigation();
	const t = useTranslations("SearchBar");

	// Pressing '/' if not already in a form element will focus the search input
	useKeyDown((event: KeyboardEvent) => {
		if (event.key === "/") {
			if (
				event.target instanceof HTMLInputElement ||
				event.target instanceof HTMLSelectElement ||
				event.target instanceof HTMLTextAreaElement
			) {
				return;
			}
			event.preventDefault();

			document.querySelector<HTMLInputElement>("input[type=search][name=q]")?.focus();
		}
	});

	const handleSubmitSearch = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const input = event.currentTarget.elements.namedItem("q") as HTMLInputElement;

		sendGTMEvent<SearchEventData>({
			event: "search",
			timestamp: Date.now(),
			device: deviceData(),
			user: userData(),
			location: locationDataFromPathname({
				locale,
				pathname,
				previousUrl,
			}),
			search: searchData(input.value),
		});

		router.push({ pathname: "/search", query: { q: input.value } }, { locale });

		document.querySelector<HTMLAnchorElement>('[aria-label="Product listing"] a')?.focus();
	};

	return (
		<form
			action="/search" // if js is disabled, the form will still work
			onSubmit={handleSubmitSearch}
			className="relative flex w-full"
			aria-labelledby="search-button"
		>
			<Input
				className="rounded-r-none border-r-0 text-md"
				name="q"
				type="search"
				defaultValue={searchParams.get("q") ?? ""}
				placeholder={t("placeholder")}
				// required
			/>
			<Button
				id="search-button"
				className="rounded-l-none"
				variant="primary"
				type="submit"
				title={t("search")}
			>
				<Icon icon="search-sm" className="size-5 bg-white" />
				{t("search")}
			</Button>
		</form>
	);
};

export const useKeyDown = (handler: (event: KeyboardEvent) => void) =>
	useEffect(() => {
		window.addEventListener("keydown", handler);
		return () => window.removeEventListener("keydown", handler);
	}, [handler]);
