"use client";

import type { Locale } from "@commerce-frontend/i18n/types";
import type {
	CompanyReview,
	ImageAssetFragment,
	MainMenuFragment,
	MenuLinkFragment,
	UspsFragmentFragment,
} from "@commerce-frontend/types";
import { Container } from "@commerce-frontend/ui/base/Container/Container";
import { cn } from "@commerce-frontend/ui/helpers/styles";
import { Link } from "@commerce-frontend/ui/primitives/Link";
import { Suspense, useEffect, useState } from "react";
import { AssetImage } from "~/components/AssetImage/AssetImage";
import { SearchBar } from "~/components/SearchBar/SearchBar";
import { USPBar } from "~/components/USPBar/USPBar";
import type { HeaderStaticLinks } from "~/lib/header-static-links";
import type { StoreMetadata } from "~/lib/store-config/context";
import { AccountIcon } from "./AccountIcon";
import { CartIcon } from "./CartIcon";
import { LanguageButton, LanguageButtonFallback } from "./LanguageButton";
import { MegaMenu } from "./MegaMenu/MegaMenu";
import { MegaMenuProvider } from "./MegaMenu/MegaMenuContext";
import NavigationBar from "./NavigationBar/NavigationBar";

type Props = {
	logo?: ImageAssetFragment;
	mainMenu: (MainMenuFragment | MenuLinkFragment)[];
	staticLinks: HeaderStaticLinks;
	locale: Locale;
	defaultLocale: Locale;
	usps?: UspsFragmentFragment;
	companyReview?: CompanyReview;
} & Pick<StoreMetadata, "siteName">;

export const Header = ({
	siteName,
	usps,
	logo,
	mainMenu,
	staticLinks,
	locale,
	companyReview,
}: Props) => {
	const [isScrolled, setIsScrolled] = useState(false);
	const [isHidden, setIsHidden] = useState(false);
	const [prevScrollPos, setPrevScrollPos] = useState(0);

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollPos = window.scrollY;
			const isScrolled = currentScrollPos > 110;
			const isHidden = prevScrollPos < currentScrollPos && currentScrollPos > 240;

			setIsScrolled(isScrolled);
			setIsHidden(isHidden);
			setPrevScrollPos(currentScrollPos);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [prevScrollPos]);

	return (
		<MegaMenuProvider>
			<header
				className={cn(
					"sticky inset-x-0 -top-11 z-20 transition-all",
					isScrolled && "pointer-events-none",
					isHidden && "max-sm:-translate-y-full",
				)}
			>
				{usps && (
					<USPBar
						className={cn("transition-all", isScrolled && "opacity-0")}
						data={usps}
						companyReview={companyReview}
						locale={locale}
					/>
				)}

				<div className={cn("sticky inset-x-0 z-20 w-full transition-all")}>
					<header className="sticky top-0 transition-all">
						<div
							className={cn(
								"relative z-30 w-full bg-white transition-all",
								isScrolled && "pointer-events-auto",
							)}
						>
							<Container>
								<div className="flex flex-wrap items-center gap-xs gap-y-3xl py-xl lg:flex-nowrap lg:gap-xs">
									<div className="order-1 shrink-0">
										<Suspense fallback={null}>
											<MegaMenu
												topMenuItems={mainMenu}
												staticLinks={staticLinks}
												logo={logo}
												locale={locale}
											/>
										</Suspense>
									</div>
									<div className="order-2 shrink-0">
										{logo && (
											<Link href={{ pathname: "/" }} aria-label="homepage" className="block">
												<AssetImage
													unoptimized // svg
													data={logo}
													alt={siteName || logo.alt || ""}
													className="h-10 w-auto"
													width={140}
													height={40}
												/>
											</Link>
										)}
									</div>
									<div className="order-last ml-auto flex w-full lg:order-3 lg:w-auto lg:max-w-[50%] lg:grow lg:px-12">
										<Suspense>
											<SearchBar />
										</Suspense>
									</div>
									<div className="order-4 ml-auto shrink-0">
										<CartIcon />
									</div>
									<div className="order-5 shrink-0">
										<AccountIcon />
									</div>
									<div className="order-6 hidden shrink-0 md:flex md:items-center">
										<Suspense fallback={<LanguageButtonFallback locale={locale} />}>
											<LanguageButton locale={locale} />
										</Suspense>
									</div>
								</div>
							</Container>
						</div>

						<div className={cn("transition-all", isScrolled && "-translate-y-full")}>
							<NavigationBar topMenuItems={mainMenu} staticLinks={staticLinks} locale={locale} />
						</div>
					</header>
				</div>
			</header>
		</MegaMenuProvider>
	);
};
