import type { Locale } from "@commerce-frontend/i18n/types";
import type { MainMenuFragment, MenuLinkFragment } from "@commerce-frontend/types";
import { Button } from "@commerce-frontend/ui/base/Button";
import { Container } from "@commerce-frontend/ui/base/Container/Container";
import { useTranslations } from "next-intl";
import type { HeaderStaticLinks } from "~/lib/header-static-links";
import { useMegaMenu } from "../MegaMenu/MegaMenuContext";

type Props = {
	topMenuItems: (MainMenuFragment | MenuLinkFragment)[];
	staticLinks: HeaderStaticLinks;
	locale: Locale;
};

const NavigationBar = ({ topMenuItems, staticLinks, locale }: Props) => {
	const { openMegaMenu } = useMegaMenu();
	const t = useTranslations("NavigationBar");

	return (
		<nav
			className="max-menuDesktop:py-md relative block items-center bg-white max-md:overflow-x-scroll"
			aria-label="menu"
		>
			<Container>
				<div className="flex items-center justify-between gap-xl pt-0 lg:py-xl">
					<ul className="max-menuDesktop:pr-container-sm flex gap-md">
						{topMenuItems.map(
							(menuItem) =>
								menuItem.__typename === "MenuItem" && (
									<li key={menuItem.label}>
										<Button variant="secondary" onClick={() => openMegaMenu(menuItem, false)}>
											{menuItem.label}
										</Button>
									</li>
								),
						)}
					</ul>
					<ul className="hidden lg:flex lg:items-center lg:gap-xl">
						{staticLinks.service && (
							<li>
								<Button
									href={staticLinks.service.link.url}
									newTab={staticLinks.service.link.newTab}
									variant="link"
									size="xl"
								>
									{staticLinks.service.label}
								</Button>
							</li>
						)}
						{staticLinks.business && (
							<li>
								<Button
									href={staticLinks.business.link.url}
									newTab={staticLinks.business.link.newTab}
									variant="link"
									size="xl"
								>
									{staticLinks.business.label}
								</Button>
							</li>
						)}
						<li>
							<Button
								href={{
									pathname: "/stores",
								}}
								locale={locale}
								variant="link"
								size="xl"
							>
								{t("store-button-label")}
							</Button>
						</li>
					</ul>
				</div>
			</Container>
		</nav>
	);
};

export default NavigationBar;
