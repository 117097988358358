import { type VariantProps, cva } from "class-variance-authority";
import type { ComponentPropsWithRef, ElementRef } from "react";
import { forwardRef } from "react";
import { Icon } from "../../base/Icon/Icon";
import { cn } from "../../helpers/styles";

export const StarRating = forwardRef<ElementRef<"div">, ComponentPropsWithRef<"div">>(
	({ children, className, ...props }, ref) => {
		return (
			<div
				ref={ref}
				className={cn("relative -mx-px flex items-center w-20 h-6", className)}
				{...props}
			>
				{children}
			</div>
		);
	},
);

StarRating.displayName = "StarRating";

export const StarRatingBackground = forwardRef<ElementRef<"div">, ComponentPropsWithRef<"div">>(
	({ children, className, ...props }, ref) => {
		return (
			<div ref={ref} className={cn("absolute flex w-20", className)} {...props}>
				{children}
			</div>
		);
	},
);

StarRatingBackground.displayName = "StarRatingBackground";

export const StarRatingForeground = forwardRef<ElementRef<"div">, ComponentPropsWithRef<"div">>(
	({ children, className, ...props }, ref) => {
		return (
			<div ref={ref} className={cn("absolute z-10 overflow-hidden", className)} {...props}>
				<div className="flex w-20">{children}</div>
			</div>
		);
	},
);

StarRatingForeground.displayName = "StarRatingForeground";

export const StarIcon = forwardRef<
	ElementRef<typeof Icon>,
	ComponentPropsWithRef<typeof Icon> & VariantProps<typeof starVariants>
>(({ className, variant = "empty", ...props }, ref) => {
	return (
		<Icon ref={ref} {...props} className={cn(starVariants({ variant }), className)} icon={"star"} />
	);
});

const starVariants = cva("mx-px w-1/5", {
	variants: {
		variant: {
			filled: "bg-orange-dark-200",
			empty: "bg-gray-200",
		},
	},
});

StarIcon.displayName = "StarIcon";
