import { cn } from "@commerce-frontend/ui/helpers/styles";
import type { HTMLAttributes } from "react";

type Props = HTMLAttributes<HTMLDivElement>;

export const Container = ({ children, className, ...props }: Props) => {
	return (
		<div
			{...props}
			className={cn("mx-auto w-full max-w-container px-container-sm lg:px-container", className)}
		>
			{children}
		</div>
	);
};
