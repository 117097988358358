import {
	StarIcon,
	StarRatingBackground,
	StarRating as StarRatingBase,
	StarRatingForeground,
} from "@commerce-frontend/ui/components/StarRating/StarRating";

type Props = {
	rating: number;
	maxRating?: number;
};

export const StarRating = ({ rating, maxRating = 5 }: Props) => {
	const percentage = Math.round((rating / maxRating) * 100);

	return (
		<StarRatingBase>
			<StarRatingBackground>
				{Array.from({ length: 5 }, (_, i) => (
					<StarIcon key={i} />
				))}
			</StarRatingBackground>
			<StarRatingForeground style={{ width: `${percentage}%` }}>
				{Array.from({ length: 5 }, (_, i) => (
					<StarIcon key={i} variant="filled" />
				))}
			</StarRatingForeground>
		</StarRatingBase>
	);
};
