import(/* webpackMode: "eager", webpackExports: ["FooterAccordion"] */ "/app/frontend/site/src/app/[locale]/(regular-layout)/_components/Footer/FooterAccordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/app/frontend/site/src/app/[locale]/(regular-layout)/_components/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsletterForm"] */ "/app/frontend/site/src/app/[locale]/(regular-layout)/_components/NewsletterForm/NewsletterForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NotificationBanner"] */ "/app/frontend/site/src/app/[locale]/(regular-layout)/_components/NotificationBanner/NotificationBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GTMClickEvent"] */ "/app/frontend/site/src/components/GoogleAnalytics/GTMClickEvent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/app/frontend/site/src/components/Image/Image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SiteConfigProvider"] */ "/app/frontend/site/src/lib/site-config-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/frontend/site/src/lib/store-config/context/storeConfig.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.15.2_next@15.2.4_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+te_0c4fe260f6ef0a4d7f2fd28a786cd267/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.15.2_next@15.2.4_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+te_0c4fe260f6ef0a4d7f2fd28a786cd267/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
