import { Image, trustedShopsLogo } from "~/components/Image/Image";
import { StarRating } from "../StarRating/StarRating";

type Props = {
	rating: number;
	maxRating?: number;
};

export const TrustedShopsRating = ({ rating, maxRating = 5 }: Props) => {
	const formattedRating = rating.toFixed(2);
	const formattedMaxRating = maxRating.toFixed(2);

	return (
		<span className="inline-flex gap-md items-center">
			<Image src={trustedShopsLogo} alt="Trusted Shops" width={24} height={24} />
			<StarRating rating={rating} maxRating={maxRating} />
			<span className="text-sm">
				<span className="font-bold">{formattedRating}</span>
				<span className="text-gray-600">/{formattedMaxRating}</span>
			</span>
		</span>
	);
};
