"use client";

import type { Locale } from "@commerce-frontend/i18n/types";
import { type CompanyReview, type UspsFragmentFragment, graphql } from "@commerce-frontend/types";
import { typography } from "@commerce-frontend/ui/base/Typography/typography";
import { cn } from "@commerce-frontend/ui/helpers/styles";
import { Link } from "@commerce-frontend/ui/primitives/Link";
import { useTranslations } from "next-intl";
import type { HTMLAttributes, PropsWithChildren } from "react";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useBreakpoint } from "~/hooks/useBreakpoint";
import { Container } from "../Container/Container";
import { TrustedShopsPopover } from "./_components/TrustedShopsPopover/TrustedShopsPopover";

graphql(/* GraphQL */ `
	fragment UspsFragment on Usps {
		id
		usp1
		usp1Link {
			url
			newTab
			hash
		}
		usp2
		usp2Link {
			url
			newTab
			hash
		}
		usp3
		usp3Link {
			url
			newTab
			hash
		}
	}
`);

type USPBarProps = {
	className?: string;
	data: UspsFragmentFragment;
	companyReview?: CompanyReview;
	locale: Locale;
};

export const USPBar = ({ className, data, companyReview, locale }: USPBarProps) => {
	const t = useTranslations("USPBar");
	const rootRef = useRef<HTMLDivElement>(null);
	const [currentUspIndex, setCurrentUspIndex] = useState(0);
	const [firstCycle, setFirstCycle] = useState(true);
	const isLgScreen = useBreakpoint("lg");

	const usps = data
		? [
				{
					type: "usp" as const,
					key: data.usp1,
					label: data.usp1,
					link: data.usp1Link?.url ? data.usp1Link : null,
				},
				{
					type: "usp" as const,
					key: data.usp2,
					label: data.usp2,
					link: data.usp2Link?.url ? data.usp2Link : null,
				},
				{
					type: "usp" as const,
					key: data.usp3,
					label: data.usp3,
					link: data.usp3Link?.url ? data.usp3Link : null,
				},
				{
					type: "ratings" as const,
				},
				{
					type: "usp" as const,
					key: `${data.usp1}-duplicate`,
					label: data.usp1,
					link: data.usp1Link?.url ? data.usp1Link : null,
				},
			].filter(({ type, label }) => (type === "ratings" && companyReview) || !!label)
		: [];

	useEffect(() => {
		if (isLgScreen || usps.length <= 2) {
			return;
		}

		const startTimer = () =>
			window.setTimeout(
				() => {
					const newIndex = (currentUspIndex + 1) % usps.length;
					setCurrentUspIndex(newIndex);

					if (newIndex === usps.length - 1) {
						// We're at the last USP, reset to the first without animation to emulate infinite scroll
						window.setTimeout(() => {
							setCurrentUspIndex(0);
							setFirstCycle(false);
						}, 700);
					}
				},
				!firstCycle && currentUspIndex === 0 ? 1300 : 2000, // deduct 700 ms after the first cycle to offset the hidden cycle reset after 700ms on the last item
			);

		let timerId = startTimer();

		const onHoverIn = () => {
			window.clearTimeout(timerId);
		};

		const onHoverOut = () => {
			timerId = startTimer();
		};

		const elem = rootRef.current;
		if (elem) {
			elem.addEventListener("touchstart", onHoverIn);
			// listening on a touch end on the entire window
			// this is to re-start animations while avoiding
			// the animation to re-start after the touch-based
			// context menu has been opened
			window.addEventListener("touchend", onHoverOut);
		}

		return () => {
			window.clearTimeout(timerId);

			if (elem) {
				elem.removeEventListener("touchstart", onHoverIn);
				window.removeEventListener("touchend", onHoverOut);
			}
		};
	}, [usps.length, currentUspIndex, setCurrentUspIndex, isLgScreen, firstCycle, setFirstCycle]);

	if (usps.length === 0) {
		return undefined;
	}

	return (
		<div className={cn("w-full select-none overflow-hidden bg-gray-50", className)} ref={rootRef}>
			<Container>
				<ul
					className={cn(
						"flex w-full flex-nowrap items-center py-2 text-center lg:translate-x-0 lg:gap-6 lg:overflow-auto",
						currentUspIndex === 0 && "translate-x-0",
						currentUspIndex === 1 && "-translate-x-full",
						currentUspIndex === 2 && "-translate-x-[200%]",
						currentUspIndex === 3 && "-translate-x-[300%]",
						currentUspIndex === 4 && "-translate-x-[400%]",
						currentUspIndex !== 0 && usps.length > 2 && "transition-transform duration-700",
					)}
					aria-label={t("label")}
				>
					{usps.map(({ type, key, label, link }, index) => {
						const itemClassName = cn(
							"lg:translate-x-0",
							currentUspIndex < index ? "translate-x-4" : "translate-x-0",
							index === usps.length - 1 && "lg:hidden",
							currentUspIndex !== 0 && usps.length > 2 && "transition-transform duration-700",
						);

						switch (type) {
							case "ratings":
								return (
									<USPBarItem key={key ?? "uspbar-rating"} className={itemClassName}>
										<TrustedShopsPopover companyReview={companyReview} locale={locale} />
									</USPBarItem>
								);

							default:
								return (
									<USPBarItem
										key={key}
										className={cn(
											itemClassName,
											"py-1 before:inline-block before:size-5 before:self-center before:bg-gray-600 before:content-[''] before:[mask-image:--icon-check-circle] before:[mask-position:center] before:[mask-repeat:no-repeat] before:[mask-size:contain]",
										)}
									>
										{link ? (
											<Link
												href={link.url + (link.hash ? `#${link.hash}` : "")}
												newTab={true}
												className={cn(typography("small"), "text-gray-600")}
											>
												{label}
											</Link>
										) : (
											<span className={cn(typography("small"), "text-gray-600")}>{label}</span>
										)}
									</USPBarItem>
								);
						}
					})}
				</ul>
			</Container>
		</div>
	);
};

type USPBarItemProps = PropsWithChildren & HTMLAttributes<HTMLLIElement>;

const USPBarItem = forwardRef<HTMLLIElement, USPBarItemProps>(
	({ children, className, ...attributes }, ref) => (
		<li
			ref={ref}
			className={cn("flex w-full shrink-0 self-center before:mr-2 lg:w-auto lg:shrink", className)}
			{...attributes}
		>
			{children}
		</li>
	),
);
USPBarItem.displayName = "USPBarItem";
