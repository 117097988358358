"use client";

import { Icon } from "@commerce-frontend/ui/base/Icon/Icon";
import { Link } from "@commerce-frontend/ui/primitives/Link";
import { useTranslations } from "next-intl";

type Props = {
	numItems: number;
};

const CartLink = ({ numItems }: Props) => {
	const t = useTranslations("CartLink");

	return (
		<Link
			className="focus:ring-accent-level-2 relative flex h-11 shrink-0 flex-col items-center justify-center gap-xxs transition-all hover:bg-gray-50 focus:bg-white lg:w-auto xxs:px-md"
			href={{
				pathname: "/cart",
			}}
			aria-label={t("order")}
		>
			<Icon icon="shopping-bag" className="size-6 shrink-0 bg-gray-400" />
			{numItems ? (
				<span className="absolute -top-2.5 right-0.5 flex h-5 items-center justify-center rounded-full border border-orange-dark-300 bg-orange-dark-200 px-sm py-xxs text-xs font-medium text-orange-dark-700">
					{numItems > 999 ? "999+" : numItems}
				</span>
			) : undefined}
			<span className="text-xs font-medium text-gray-500">{t("order")}</span>
		</Link>
	);
};

export default CartLink;
