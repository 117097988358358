"use client";

import { sum } from "@labdigital/toolkit";
import CartLink from "~/components/CartLink/CartLink";
import { useCart } from "~/hooks/cart/useCart";

export const CartIcon = () => {
	const { cart } = useCart();
	const numCartItems = sum(cart?.lineItems ?? [], (g) => g.quantity);

	return <CartLink numItems={numCartItems} />;
};
