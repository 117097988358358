"use client";

import type { Locale } from "@commerce-shared/site-config";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

type Alternate = {
	locale: string;
	href: string;
};

export const useAlternates = (locale: string, locales: Locale[]): Alternate[] => {
	const [alternates, setAlternates] = useState<Alternate[]>([]);
	const pathname = usePathname();

	useEffect(() => {
		const createAlternate = (loc: string, href: string) => ({
			locale: loc,
			href: relativePath(href),
		});

		// Extract alternates from the head
		const headAlternates = Array.from(document.head.querySelectorAll("link[rel='alternate']")).map(
			(link) =>
				createAlternate(link.getAttribute("hreflang") ?? "", link.getAttribute("href") ?? ""),
		);

		// Initialize alternates array with the current locale
		const alternatesArray: Alternate[] = [{ locale, href: pathname }];

		// Merge head alternates and additional locales
		for (const loc of locales) {
			const headAlternate = headAlternates.find((alt) => alt.locale === loc);
			if (headAlternate) {
				alternatesArray.push(headAlternate);
			} else {
				alternatesArray.push({
					locale: loc,
					href: `/${loc.toLowerCase()}`,
				});
			}
		}

		setAlternates(alternatesArray);
	}, [locale, pathname, locales]);

	return alternates;
};

const relativePath = (href: string) => {
	const url = new URL(href, window.location.origin);
	return url.pathname + url.search;
};
