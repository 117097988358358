import type { InputHTMLAttributes } from "react";
import { forwardRef } from "react";
import { cn } from "../../helpers/styles";
import { Icon } from "../Icon/Icon";

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
	suggestions?: string[] | null;
	state?: "error" | "success";
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
	({ className, state, suggestions, ...props }, ref) => {
		return (
			<div className="relative grow">
				<input
					className={cn(
						"w-full min-h-11 appearance-none rounded-xs border border-gray-300 bg-white px-lg py-md text-md shadow-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:border-brand-500 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50",
						state === "error" &&
							"border-error-300 focus-visible:border-error-300 focus-visible:ring-error-200 pr-5xl",
						className,
					)}
					ref={ref}
					{...props}
					list={suggestions && suggestions.length > 0 ? `${props.name}-suggestions` : undefined}
				/>

				{state === "error" ? (
					<Icon
						icon="alert-circle"
						className="absolute right-3 top-1/2 -translate-y-1/2 size-4 bg-error-500"
					/>
				) : null}

				{suggestions && suggestions.length > 0 ? (
					<datalist id={`${props.name}-suggestions`}>
						{suggestions.map((value) => (
							<option key={value} value={value} />
						))}
					</datalist>
				) : null}
			</div>
		);
	},
);

Input.displayName = "Input";
